<template>
  <div class="search-container">
    <!-- 中上方问题搜索框+搜索按钮 -->
    <el-row :gutter="10" style="display: flex;justify-content: center;margin-bottom: 20px;">
      <el-col :span="8">
        <el-input v-model="searchForm.keyword" placeholder="请输入查询关键词"></el-input>
      </el-col>
      <el-col :span="1.5">
        <el-button type="primary" @click="handleSearch">搜索</el-button>
      </el-col>
      <el-col :span="1.5">
        <el-button type="success" @click="resetSearchForm">清空</el-button>
      </el-col>
    </el-row>

    <!-- 搜索框下面一行排列开来备选筛选字段 -->

    <el-row :gutter="24" class="alternative-col">
      <el-col :span="6" class="alternative-col">
        <el-input v-model="searchForm.age" placeholder="年龄最小值" @input="validateRange('age', 0, 140)"></el-input>
        <div style="margin:0 5px">至</div>
        <el-input v-model="searchForm.agem" placeholder="年龄最大值" @input="validateRange('agem', 0, 140)"></el-input>
      </el-col>
      <el-col :span="6" class="alternative-col">
        <el-input v-model="searchForm.sbp" placeholder="收缩压最小值" @input="validateRange('sbp', 0, 250)"></el-input>
        <div style="margin:0 5px">至</div>
        <el-input v-model="searchForm.abpm" placeholder="收缩压最大值" @input="validateRange('abpm', 0, 250)"></el-input>
      </el-col>
      <el-col :span="6" class="alternative-col">
        <el-input v-model="searchForm.dbp" placeholder="舒张压最小值" @input="validateRange('dbp', 0, 200)"></el-input>
        <div style="margin:0 5px">至</div>
        <el-input v-model="searchForm.dbpm" placeholder="舒张压最大值" @input="validateRange('dbpm', 0, 200)"></el-input>
      </el-col>
      <el-col :span="6" class="alternative-col">
        <el-input v-model="searchForm.ea1" placeholder="E/A比值最小值" @input="validateRange('ea1', 0, 2)"></el-input>
        <div style="margin:0 5px">至</div>
        <el-input v-model="searchForm.ea1m" placeholder="E/A比值最大值" @input="validateRange('ea1m', 0, 2)"></el-input>
      </el-col>
    </el-row>
    <el-row :gutter="20" class="alternative-col" style="margin:20px 0px;">
      <el-col :span="6" class="alternative-col">
        <el-date-picker v-model="searchForm.time" type="date" placeholder="开始时间" style="width: 100%;"></el-date-picker>
        <div style="margin:0 5px">至</div>
        <el-date-picker v-model="searchForm.endTime" type="date" placeholder="结束时间" style="width: 100%;"></el-date-picker>
      </el-col>
      <el-col :span="4" class="alternative-col">
        <el-input v-model="searchForm.lvef" placeholder="LVEF(%)" @input="validateRange('lvef', 0, 100)"></el-input>
      </el-col>
      <el-col :span="4" class="alternative-col">
        <el-input v-model="searchForm.sjghd" placeholder="室间隔厚度(mm)" @input="validateRange('sjghd', 0, 30)"></el-input>
      </el-col>
      <el-col :span="5">
        <el-select v-model="searchForm.sampleType" placeholder="请选择样本类型" clearable>
          <el-option label="血" value="blood"></el-option>
          <el-option label="标本" value="sample"></el-option>
          <el-option label="其他" value="other"></el-option>
        </el-select>
      </el-col>
    </el-row>
    <el-row :gutter="20" class="alternative-col">
      <el-col :span="4">
        <el-select v-model="searchForm.gender" placeholder="请选择性别" clearable>
          <el-option label="男" value="1"></el-option>
          <el-option label="女" value="2"></el-option>
        </el-select>
      </el-col>
      <el-col :span="4">
        <el-select v-model="searchForm.disease" placeholder="请选择疾病" clearable>
          <el-option label="HCM" value="HCM"></el-option>
          <el-option label="DCM" value="DCM"></el-option>
          <el-option label="AS" value="AS"></el-option>
          <el-option label="PCI" value="PCI"></el-option>
          <el-option label="HF" value="HF"></el-option>
          <el-option label="Healthycontrol" value="Healthycontrol"></el-option>
          <el-option label="AD" value="AD"></el-option>
          <el-option label="specific" value="specific"></el-option>
          <el-option label="家系" value="家系"></el-option>
        </el-select>
      </el-col>
      <el-col :span="4">
        <el-select v-model="searchForm.smoking" placeholder="是否吸烟" clearable>
          <el-option label="是" value="1"></el-option>
          <el-option label="否" value="0"></el-option>
        </el-select>
      </el-col>
      <el-col :span="4">
        <el-select v-model="searchForm.drinking" placeholder="是否饮酒" clearable>
          <el-option label="是" value="1"></el-option>
          <el-option label="否" value="0"></el-option>
        </el-select>
      </el-col>

    </el-row>

    <!-- 搜索后的结果列表，分页展示 -->
    <el-table :data="dataList" style="width: 100%; margin-top: 20px;cursor:pointer;" @row-dblclick="handleRowDblClick" @cell-mouse-enter="showTooltip" :header-cell-style="{ textAlign: 'center', verticalAlign: 'middle' }" :cell-style="{ textAlign: 'center', verticalAlign: 'middle' }">
      <el-table-column prop="name" label="名称"></el-table-column>
      <el-table-column prop="gender" label="性别">
        <template slot-scope="scope">
          <span>{{ scope.row.gender ===null?'':(scope.row.gender == 1 ? '男' : '女') }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="age" label="年龄"></el-table-column>
      <el-table-column prop="disease" label="疾病"></el-table-column>
      <el-table-column prop="sbp" label="收缩压"></el-table-column>
      <el-table-column prop="dbp" label="舒张压"></el-table-column>
      <el-table-column prop="smoking" label="是否吸烟">
        <template slot-scope="scope">
          <span>{{ scope.row.smoking ===null?'':(scope.row.smoking == 1 ? '是' : '否') }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="drinking" label="是否饮酒">
        <template slot-scope="scope">
          <span>{{ scope.row.drinking ===null?'':(scope.row.drinking == 1 ? '是' : '否') }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="ea1" label="E/A比值"></el-table-column>
      <el-table-column prop="samplingDate" label="取材时间"></el-table-column>
      <el-table-column prop="lvef" label="LVEF"></el-table-column>
      <el-table-column prop="sjghd" label="室间隔厚度"></el-table-column>
      <el-table-column prop="sampleType" label="样本类型">
        <template slot-scope="scope">
          <!-- 先去除收尾空格 -->
          <span>{{ scope.row.sampleType ===null?'':(scope.row.sampleType.trim() === 'blood' ? '血' : (scope.row.sampleType.trim() === 'sample' ? '标本' : '其他')) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="coordinate" label="盒子在冰箱位置"></el-table-column>
    </el-table>
    <div class="pagination-container">
      <el-pagination @current-change="handlePageChange" @size-change="handleSizeChange" :current-page="current" :page-size="size" :page-sizes="[10, 20, 30, 40]" layout="total, sizes, prev, pager, next" :total="total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { getSpecimenListBySearch } from '@/api/specimen'

export default {
  data() {
    return {
      searchForm: {
        gender: "",//性别
        age: "",//年龄
        agem: "",//最大年龄
        sbp: "",//收缩压
        abpm: "",//最大收缩压
        dbp: "",//舒张压
        dbpm: "",//最大舒张压
        smoking: "",//是否吸烟（是1否0）
        drinking: "",//是否饮酒（是1否0）
        ea1: "",//E/A比值
        ea1m: "",//最大E/A比值
        keyword: "",//搜索关键字
        disease: "",//疾病
        time: "",//开始时间
        endTime: "",//结束时间
        lvef: "",//LVEF
        sjghd: "",//室间隔厚度
        sampleType: "",//样本类型(血blood 标本sample 其他other)
      },
      dataList: [],
      current: 1,
      size: 10,
      total: 0,
    }
  },
  methods: {
    // 时间格式化为yyyy-MM-dd
    formatDate(date) {
      if (!date) return '';
      date = new Date(date);
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();
      return `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`;
    },
    handleSearch() {
      // 调用API进行搜索
      const params = new URLSearchParams({
        gender: this.searchForm.gender,
        age: this.searchForm.age,
        agem: this.searchForm.agem,
        sbp: this.searchForm.sbp,
        abpm: this.searchForm.abpm,
        dbp: this.searchForm.dbp,
        dbpm: this.searchForm.dbpm,
        smoking: this.searchForm.smoking,
        drinking: this.searchForm.drinking,
        ea1: this.searchForm.ea1,
        ea1m: this.searchForm.ea1m,
        keyword: this.searchForm.keyword,
        disease: this.searchForm.disease,
        time: this.formatDate(this.searchForm.time),
        endTime: this.formatDate(this.searchForm.endTime),
        lvef: this.searchForm.lvef,
        sjghd: this.searchForm.sjghd,
        sampleType: this.searchForm.sampleType,
        current: this.current,
        size: this.size
      }).toString();
      getSpecimenListBySearch(params).then(res => {
        this.dataList = res.data.records;
        this.total = res.data.total;
      })
    },
    handlePageChange(page) {
      this.current = page;
      this.handleSearch();
    },
    handleSizeChange(size) {
      this.size = size;
      this.handleSearch();
    },
    resetSearchForm() {
      this.searchForm = {
        keyword: '',
        age: '',
        agem: '',
        sbp: '',
        abpm: '',
        dbp: '',
        dbpm: '',
        ea1: '',
        ea1m: '',
        gender: '',
        disease: '',
        smoking: '',
        drinking: '',
        time: '',
        endTime: '',
        lvef: '',
        sjghd: '',
        sampleType: ''
      };
    },
    validateRange(field, min, max) {
      // min、max验证必须是数字
      if (isNaN(this.searchForm[field])) {
        this.$set(this.searchForm, field, '');
        this.$message.warning('请输入数字');
      } else if (this.searchForm[field] < min) {
        this.$set(this.searchForm, field, min);
        this.$message.warning(`输入值不能小于${min}`);
      } else if (this.searchForm[field] > max) {
        this.$set(this.searchForm, field, max);
        this.$message.warning(`输入值不能大于${max}`);
      }
    },
    handleRowDblClick(row) {
      this.$router.push(`/specimen-detail?bId=${row.boxId}`);
    },
    showTooltip(row, column, cell, event) {
      event.target.title = "双击查看标本详情";
    }
  }
}
</script>

<style scoped>
.search-container {
  padding: 20px;
}
.alternative-col {
  display: flex;
  justify-content: center;
  align-items: center;
}
.pagination-container {
  margin-top: 15px;
  display: flex;
  justify-content: center;
}
</style>